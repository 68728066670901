define('ember-message-bus/services/message-bus', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var isBlank = _ember['default'].isBlank;
  var isNone = _ember['default'].isNone;
  var set = _ember['default'].set;

  exports['default'] = Service.extend({
    _subscriptionMap: computed(function () {
      return {};
    }),

    publish: function publish(name) {
      var _this = this;

      for (var _len = arguments.length, messages = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        messages[_key - 1] = arguments[_key];
      }

      var markedForCleanup = [];

      (get(this, '_subscriptionMap.' + name) || []).forEach(function (subscription) {
        if (isNone(subscription.context) || get(subscription.context, 'isDestroyed')) {
          return markedForCleanup.push(subscription.context);
        }

        subscription.callback.apply(subscription.context, messages);
      });

      markedForCleanup.forEach(function (context) {
        return _this.unsubscribe(name, context);
      });
    },

    subscribe: function subscribe(name, context, callback) {
      var subscriptions = get(this, '_subscriptionMap.' + name) || set(this, '_subscriptionMap.' + name, []);

      subscriptions.push({ callback: callback, context: context });
    },

    unsubscribe: function unsubscribe(name, context, callback) {
      var subscriptionMap = get(this, '_subscriptionMap');
      var subscriptions = get(subscriptionMap, name);

      if (isBlank(subscriptions)) {
        return;
      }

      var blankCallback = isBlank(callback);

      var indices = subscriptions.reduce(function (indices, subscription, index) {
        if ((blankCallback || subscription.callback === callback) && subscription.context === context) {
          indices.push(index);
        }

        return indices;
      }, []);

      indices.reverse().forEach(function (index) {
        return subscriptions.splice(index, 1);
      });

      if (subscriptions.length === 0) {
        delete subscriptionMap[name];
      }
    },

    unsubscribeAll: function unsubscribeAll(context) {
      var _this2 = this;

      var subscriptionMap = get(this, '_subscriptionMap');

      Object.keys(subscriptionMap).forEach(function (name) {
        return _this2.unsubscribe(name, context);
      });
    }
  });
});
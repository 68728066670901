define('ember-ui-sortable/components/ui-draggable', ['exports', 'ember', 'ember-ui-sortable/templates/components/ui-draggable'], function (exports, _ember, _emberUiSortableTemplatesComponentsUiDraggable) {
  'use strict';

  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberUiSortableTemplatesComponentsUiDraggable['default'],
    disabled: false,

    tagName: 'div',

    uiOptions: ['addClasses', 'axis', 'appendTo', 'connectToSortable', 'containment', 'cursor', 'cursorAt', 'delay', 'disabled', 'distance', 'grid', 'handle', 'helper', 'opacity', 'refreshPositions', 'revert', 'revertDuration', 'scroll', 'scrollSensitivity', 'scrollSpeed', 'snap', 'snapMode', 'snapTolerance', 'stack', 'zIndex'],

    destroyDraggable: on('willDestroyElement', function () {
      this.$().draggable('destroy');
    }),

    initDraggable: on('didInsertElement', function () {
      var _this = this;

      var opts = {};

      ['start'].forEach(function (callback) {
        opts[callback] = run.bind(_this, callback);
      });

      this.$().draggable(opts);

      this.get('uiOptions').forEach(function (option) {
        _this._bindDraggableOption(option);
      });
    }),

    contentObserver: observer('content', function () {
      run.scheduleOnce('afterRender', this, this._refreshDraggable);
    }),

    start: function start(event, ui) {
      ui.helper.addClass('draggable-item');
    },

    _bindDraggableOption: function _bindDraggableOption(key) {
      this.addObserver(key, this, this._optionDidChange);

      if (key in this) {
        this._optionDidChange(this, key);
      }

      this.on('willDestroyElement', this, function () {
        this.removeObserver(key, this, this._optionDidChange);
      });
    },

    _optionDidChange: function _optionDidChange(sender, key) {
      this.$().draggable('option', key, this.get(key));
    },

    _refreshDraggable: function _refreshDraggable() {
      if (this.isDestroying) {
        return;
      }
      this.$().draggable('refresh');
    }
  });
});
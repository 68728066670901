define('ember-collapsible-panel/components/cp-panel-toggle/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'a',
    classNames: ['cp-Panel-toggle'],
    classNameBindings: ['isOpen:cp-is-open'],

    // So taps register in iOS
    attributeBindings: ['href'],
    href: '#',

    click: function click(e) {
      e.preventDefault();
      this.get('on-click')();
    }

  });
});
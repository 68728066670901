define('ember-image-drop/components/image-drop', ['exports', 'ember', 'ember-image-drop/templates/components/image-drop'], function (exports, _ember, _emberImageDropTemplatesComponentsImageDrop) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberImageDropTemplatesComponentsImageDrop['default'],
    classNames: ['ember-image-drop'],
    attributeBindings: ['style'],
    placeholder: "",
    helpText: "Drop your image here, or click to select",
    hideTextOnImage: true,

    image: null,
    file: null,

    textStyle: _ember['default'].computed('image', function () {
      var textStyle = "";
      if (this.get('hideTextOnImage') && this.get('image')) {
        textStyle = "display: none;";
      }
      return _ember['default'].String.htmlSafe(textStyle);
    }),

    style: _ember['default'].computed('image', function () {
      var backgroundStyle = "";
      if (this.get('image')) {
        backgroundStyle = 'background-image: url(' + this.get('image') + ');';
      }

      return _ember['default'].String.htmlSafe(backgroundStyle);
    }),

    setup: _ember['default'].on('willInsertElement', function () {
      var _this = this;

      var $input = this.$('input');
      $input.on('change', function (event) {
        _this.handleFileDrop(event.target.files[0]);
      });
    }),

    handleFileDrop: function handleFileDrop(file) {
      var _this2 = this;

      if (file == null) {
        return;
      }

      this.set('file', file);
      var reader = new FileReader();
      reader.onload = function (e) {
        var fileToUpload = e.target.result;
        _ember['default'].run(function () {
          _this2.set('image', fileToUpload);
        });
      };
      reader.readAsDataURL(file);
    },

    drop: function drop(event) {
      var _this3 = this;

      event.preventDefault();
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        return this.handleFileDrop(event.dataTransfer.files[0]);
      }

      var imageUrl = event.dataTransfer.getData('URL');

      if (!imageUrl) {
        return;
      }

      this.convertImgToBase64URL(imageUrl, function (base64) {
        _this3.set('image', base64);
      });
    },

    convertImgToBase64URL: function convertImgToBase64URL(url, callback, outputFormat) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        var canvas = document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'),
            dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null;
      };
      img.src = url;
    }
  });
});
define('ember-select/components/x-select', ['exports', 'ember', 'ember-select/templates/components/x-select'], function (exports, _ember, _emberSelectTemplatesComponentsXSelect) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var isPresent = _ember['default'].isPresent;
  var run = _ember['default'].run;

  var isEdgeIe = typeof StyleMedia !== 'undefined';

  exports['default'] = Component.extend({
    layout: _emberSelectTemplatesComponentsXSelect['default'],
    classNames: ['ember-select'],
    classNameBindings: ['isOpen:es-open', 'isFocus:es-focus', 'canSearch::es-select', 'multiple:es-multiple'],

    autofocus: false,
    canSearch: true,
    disabled: false,
    dropdown: 'select-dropdown',
    freeText: false,
    isDirty: false,
    isFocus: false,
    isOpen: false,
    openOnFocus: false,
    placeholder: 'Type...',
    required: false,
    token: '',
    value: '',

    labelKey: 'label',
    valueKey: 'value',

    messageBus: inject.service(),

    canClear: computed.and('enabled', 'canSearch', 'hasOptions'),
    canOpen: computed.or('hasInput', 'openOnFocus'),
    enabled: computed.not('disabled'),
    hasDropdown: computed.and('enabled', 'hasModel'),
    hasInput: computed.notEmpty('token'),
    hasModel: computed.notEmpty('model'),
    hasOptions: computed.or('hasInput', 'hasValues'),
    hasValues: computed.notEmpty('values'),
    multiple: computed.bool('values'),
    shouldFilter: computed.or('isDirty', 'multiple', 'hasChanged'),

    input: computed(function () {
      return document.querySelector('#' + this.elementId + ' input');
    }),

    hasChanged: computed('token', 'value', function () {
      var token = this.get('token');
      var option = this.get('value');

      if (isPresent(token) && isPresent(option)) {
        var _retrieveOption = this.retrieveOption(option);

        var label = _retrieveOption.label;

        return token !== label;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.disabled) {
        this.set('canSearch', false);
      }

      if (!this.canSearch) {
        this.set('openOnFocus', true);
      }

      /* IE10+ Triggers an input event when focus changes on
       * an input element if the element has a placeholder.
       * https://connect.microsoft.com/IE/feedback/details/810538/
       */
      if (document.documentMode) {
        this.set('placeholder', null);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var value = this.get('value');
      if (isPresent(value)) {
        run.next(this, function () {
          return _this.setOption(value);
        });
      }
    },

    didUpdateAttrs: function didUpdateAttrs(attrs) {
      this._super.apply(this, arguments);

      // Need to open on lazy models
      if (this.get('isDirty')) {
        this.open();
      }

      // Update input if value has changed
      var newValue = attrs.newAttrs.value;
      var oldValue = attrs.oldAttrs.value;
      if (oldValue && newValue && oldValue.value !== newValue.value) {
        var _retrieveOption2 = this.retrieveOption(newValue.value);

        var label = _retrieveOption2.label;

        if (label !== this.get('token')) {
          this.setOption(newValue.value);
        }
      }
    },

    actions: {
      blur: function blur() {
        if (this.get('isDirty')) {
          // Clear unallowed input in strict single mode
          var option = this.get('freeText') ? this.get('token') : '';
          this.setOption(option, false, !this.get('multiple'));
        }

        this.setProperties({
          isFocus: false,
          isOpen: false
        });
      },

      change: function change(query) {
        this.set('token', query);
        this.set('isDirty', true);
        this.sendAction('onChange', query);

        if (isPresent(query)) {
          this.open();
        }
      },

      clear: function clear() {
        this.setOption('', false, !this.get('multiple'));
        this.sendAction('onClear');
        this.send('focus');
      },

      dropdown: function dropdown() {
        var isOpen = this.toggleProperty('isOpen');
        if (isOpen) {
          this.get('input').focus();
        }
      },

      focus: function focus() {
        if (this.get('disabled')) {
          return this.send('blur');
        }

        var input = this.get('input');
        if (input) {
          input.focus();
        }

        if (input && !this.get('isFocus') && this.get('canSearch')) {
          // Select text (similar to TAB)
          input.select();
        }

        if (!this.get('isOpen')) {
          this.open();
        }
      },

      keypress: function keypress(e) {
        var isOpen = this.get('isOpen');

        switch (e.keyCode) {
          case 8:
            {
              // Backspace
              var values = this.get('values');
              if (isPresent(values) && this.get('token') === '') {
                var last = this.getElement(values, get(values, 'length') - 1);
                this.attrs.onRemove(last);
                e.preventDefault();
              }

              break;
            }

          case 9: // TAB
          case 13:
            // Enter
            if (isOpen) {
              this.get('messageBus').publish('select-key', e);
            } else if (this.get('freeText')) {
              this.send('select', this.get('token'), false);
            }

            break;
          case 27:
            // ESC
            if (this.get('canSearch') && this.get('hasInput')) {
              this.send('clear');
            } else {
              this.set('isOpen', false);
            }
            break;
          case 38: // Up Arrow
          case 40:
            // Down Arrow
            if (isOpen) {
              this.get('messageBus').publish('select-key', e);
            } else {
              this.set('isOpen', true);
            }

            e.preventDefault();
            break;
        }
      },

      remove: function remove(selection) {
        this.attrs.onRemove(selection);
        this.send('focus');
      },

      select: function select(option, selected) {
        var isNew = !selected && this.get('freeText') && this.get('isDirty');
        var allowNew = isPresent(this.attrs.onCreate);
        var valid = isPresent(option);

        /* Notify when option is either
         *  - selected
         *  - new, empty and cannot be created */
        var notify = selected || isNew && !allowNew;

        if (allowNew && valid && isNew) {
          this.attrs.onCreate(option);
        }

        this.setOption(option, selected, notify);

        /* Blur on selection when single
         * IE & Edge do not run the events in proper order */
        if (!this.get('multiple') && !isEdgeIe) {
          this.get('input').blur();
        }
      }
    },

    // Handle plain arrays and Ember Data relationships
    getElement: function getElement(model, position) {
      return model[position] || model.objectAt(position);
    },

    open: function open() {
      this.setProperties({
        isOpen: this.get('hasDropdown') && this.get('canOpen'),
        isFocus: true
      });
    },

    /* Retrieve `option`, `value` and `label` given a selection
     * which can be either an option (object) or a value */
    retrieveOption: function retrieveOption(option) {
      var _this2 = this;

      var model = this.get('model');
      var label = option;
      var value = option;

      if (typeof option === 'object') {
        label = get(option, this.get('labelKey'));
        value = get(option, this.get('valueKey'));
      } else if (isPresent(model) && typeof this.getElement(model, 0) === 'object') {
        (function () {
          var id = _this2.get('valueKey');
          option = model.filter(function (x) {
            return get(x, id) === option;
          }).shift();

          if (option) {
            label = get(option, _this2.get('labelKey'));
          }
        })();
      }

      return { option: option, value: value, label: label };
    },

    setOption: function setOption(selection, selected, notify) {
      var _retrieveOption3 = this.retrieveOption(selection);

      var option = _retrieveOption3.option;
      var value = _retrieveOption3.value;
      var label = _retrieveOption3.label;

      if (this.get('multiple')) {
        label = '';
      }

      if (!selected && notify && this.get('required')) {
        return this.setOption(this.get('value'));
      }

      if (this.get('canSearch')) {
        this.set('token', label);
      }

      // Ensure the component hasn't been destroyed before updating
      var input = this.get('input');
      if (input) {
        input.value = label;
      }

      this.set('isDirty', false);

      if (notify) {
        this.sendAction('onSelect', value, option, selected);
        this.set('isOpen', false);
      }
    }
  });
});
define('ember-select/components/select-dropdown', ['exports', 'ember', 'ember-select/templates/components/select-dropdown', 'ember-select/utils/tree', 'ember-select/utils/view'], function (exports, _ember, _emberSelectTemplatesComponentsSelectDropdown, _emberSelectUtilsTree, _emberSelectUtilsView) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var isNone = _ember['default'].isNone;
  var isPresent = _ember['default'].isPresent;
  var on = _ember['default'].on;
  var run = _ember['default'].run;

  exports['default'] = Component.extend({
    layout: _emberSelectTemplatesComponentsSelectDropdown['default'],
    list: null,

    messageBus: inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      this.get('messageBus').subscribe('select-key', this, this.keys);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var options = this.getProperties('valueKey', 'labelKey');
      var model = this.get('model');
      var list = (0, _emberSelectUtilsTree.buildTree)(model, options);

      this.setProperties({ list: list });
    },

    options: computed('token', 'model.[]', 'values.[]', function () {
      if (this.get('shouldFilter')) {
        this.filterModel();
      }

      return this.get('list');
    }),

    actions: {
      hover: function hover(node) {
        var selected = this.get('selected');
        if (selected) {
          selected.set('isSelected', false);
        }

        this.set('selected', node);
        node.set('isSelected', true);
      },

      select: function select(node) {
        this.attrs.select(node.content, true);
      }
    },

    /* Filter out existing selections. Mark everything
     visible if no search, otherwise update visiblity. */
    filterModel: function filterModel() {
      var list = this.get('list');
      var token = this.get('token');
      var values = this.get('values');

      list.forEach(function (el) {
        return el.set('isVisible', false);
      });

      if (isPresent(values)) {
        list = list.filter(function (el) {
          return values.indexOf(el.content) === -1;
        });
      }

      if (isEmpty(token)) {
        list.forEach(function (el) {
          return el.set('isVisible', true);
        });
      } else {
        token = token.toLowerCase();
        this.setVisibility(list, token);
      }

      // Mark first visible element as selected
      if (!this.get('freeText') && isPresent(token) && list.some(function (x) {
        return get(x, 'isVisible');
      })) {
        var _list$filter = list.filter(function (x) {
          return get(x, 'isVisible');
        });

        var _list$filter2 = _slicedToArray(_list$filter, 1);

        var firstVisible = _list$filter2[0];

        firstVisible.set('isSelected', true);
        this.set('selected', firstVisible);
      }
    },

    keys: function keys(event) {
      var selected = this.get('selected');

      switch (event.keyCode) {
        case 9: // TAB
        case 13:
          // Enter
          this.tabEnterKeys(selected);
          break;

        case 38: // Up
        case 40:
          // Down
          this.upDownKeys(selected, event);
          break;
      }
    },

    // Prevent event bubbling up
    mouseDown: function mouseDown(event) {
      event.preventDefault();
    },

    // Down: 40, Up: 38
    move: function move(list, selected, direction) {
      if (isPresent(selected)) {
        selected.set('isSelected', false);
      }

      if (isEmpty(list)) {
        return;
      }

      var index = list.indexOf(selected);
      var node = undefined;

      if (direction === 38) {
        if (index !== -1) {
          node = list[index - 1];
        }

        if (isNone(node)) {
          node = list[list.length - 1];
        }
      } else if (direction === 40) {
        if (index !== -1) {
          node = list[index + 1];
        }

        if (isNone(node)) {
          node = list[0];
        }
      }

      this.set('selected', node);
      node.set('isSelected', true);

      run.next(this, _emberSelectUtilsView.bringInView, '.es-options', '.es-highlight');
    },

    setVisibility: function setVisibility(list, token) {
      list.filter(function (el) {
        return get(el, 'name').toLowerCase().indexOf(token) > -1;
      }).forEach(function (el) {
        return el.set('isVisible', true);
      });
    },

    tabEnterKeys: function tabEnterKeys(selected) {
      if (selected && this.get('options').includes(selected)) {
        this.send('select', selected);
      } else if (this.get('freeText')) {
        this.attrs.select(this.get('token'));
      }
    },

    upDownKeys: function upDownKeys(selected, event) {
      var list = this.get('list').filterBy('isVisible');
      this.move(list, selected, event.keyCode);
    }
  });
});
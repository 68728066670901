define('ember-tooltips/components/lazy-render-wrapper', ['exports', 'ember', 'ember-tooltips/templates/components/lazy-render-wrapper'], function (exports, _ember, _emberTooltipsTemplatesComponentsLazyRenderWrapper) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var computed = _ember['default'].computed;
	var get = _ember['default'].get;
	var run = _ember['default'].run;
	var $ = _ember['default'].$;

	// https://github.com/emberjs/rfcs/issues/168
	// https://github.com/emberjs/ember.js/pull/12500
	function getParent(view) {
		if (get(view, 'tagName') === '') {
			// Beware: use of private API! :(
			if (_ember['default'].ViewUtils && _ember['default'].ViewUtils.getViewBounds) {
				return $(_ember['default'].ViewUtils.getViewBounds(view).parentElement);
			} else {
				return $(view._renderNode.contextualElement);
			}
		} else {
			return view.$().parent();
		}
	}

	// this const is also used in lazy-render-test.js
	// to ensure each interaction type causes a render
	var INTERACTION_EVENT_TYPES = ['mouseenter', 'click', 'focusin'];

	exports.INTERACTION_EVENT_TYPES = INTERACTION_EVENT_TYPES;

	var PASSABLE_PROPERTIES = ['delay', 'delayOnChange', 'duration', 'effect', 'event', 'hideOn', 'keepInWindow', 'side', 'showOn', 'spacing', 'isShown', 'tooltipIsVisible', 'hideDelay', 'target',

	// non-publicized attributes
	'updateFor', 'targetAttachment', 'attachment', 'role', 'tabindex'];

	var PASSABLE_ACTIONS = ['onDestroy', 'onHide', 'onRender', 'onShow',

	// deprecated lifecycle actions
	'onTooltipDestroy', 'onTooltipHide', 'onTooltipRender', 'onTooltipShow'];

	var PASSABLE_OPTIONS = PASSABLE_PROPERTIES.concat(PASSABLE_ACTIONS);

	exports['default'] = _ember['default'].Component.extend({
		tagName: '',
		layout: _emberTooltipsTemplatesComponentsLazyRenderWrapper['default'],

		passedPropertiesObject: computed.apply(undefined, _toConsumableArray(PASSABLE_OPTIONS).concat([function () {
			var _this = this;

			return PASSABLE_OPTIONS.reduce(function (passablePropertiesObject, key) {
				// if a property has been declared by Component extension ( TooltipOnElement.extend )
				// or by handlebars instantiation ( {{tooltip-on-element}} ) then that property needs
				// to be passed from this wrapper to the lazy-rendered tooltip or popover component

				var value = _this.get(key);

				if (!_ember['default'].isNone(value)) {
					if (PASSABLE_ACTIONS.indexOf(key) >= 0) {
						// if a user has declared a lifecycle action property (onShow='someFunc')
						// then we must pass down the correctly-scoped action instead of value

						passablePropertiesObject[key] = function () {
							return _this.sendAction(key);
						};
					} else {
						passablePropertiesObject[key] = value;
					}
				}

				return passablePropertiesObject;
			}, {});
		}])),

		enableLazyRendering: false,
		_hasUserInteracted: false,
		_hasRendered: false,
		_shouldRender: computed('isShown', 'tooltipIsVisible', 'enableLazyRendering', '_hasUserInteracted', function () {
			// if isShown, tooltipIsVisible, !enableLazyRendering, or _hasUserInteracted then
			// we return true and set _hasRendered to true because
			// there is never a scenario where this wrapper should destroy the tooltip

			if (this.get('_hasRendered')) {

				return true;
			} else if (this.get('isShown') || this.get('tooltipIsVisible')) {

				this.set('_hasRendered', true);
				return true;
			} else if (!this.get('enableLazyRendering')) {

				this.set('_hasRendered', true);
				return true;
			} else if (this.get('_hasUserInteracted')) {

				this.set('_hasRendered', true);
				return true;
			}

			return false;
		}),

		didInsertElement: function didInsertElement() {
			var _this2 = this;

			this._super.apply(this, arguments);

			if (this.get('_shouldRender')) {
				// if the tooltip _shouldRender then we don't need
				// any special $parent event handling
				return;
			}

			var $parent = getParent(this);

			INTERACTION_EVENT_TYPES.forEach(function (eventType) {
				$parent.on(eventType + '.lazy-ember-popover', function () {
					if (_this2.get('_hasUserInteracted')) {
						$parent.off(eventType + '.lazy-ember-popover');
					} else {
						_this2.set('_hasUserInteracted', true);
						run.next(function () {
							$parent.trigger(eventType);
						});
					}
				});
			});
		},

		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			var $parent = getParent(this);
			INTERACTION_EVENT_TYPES.forEach(function (eventType) {
				$parent.off(eventType + '.lazy-ember-popover');
			});
		}
	});
});
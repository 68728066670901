define('ember-select/components/select-dropdown-group', ['exports', 'ember', 'ember-select/templates/components/select-dropdown-group', 'ember-select/components/select-dropdown', 'ember-select/utils/tree'], function (exports, _ember, _emberSelectTemplatesComponentsSelectDropdownGroup, _emberSelectComponentsSelectDropdown, _emberSelectUtilsTree) {
  'use strict';

  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;

  exports['default'] = _emberSelectComponentsSelectDropdown['default'].extend({
    layout: _emberSelectTemplatesComponentsSelectDropdownGroup['default'],
    groups: null,
    list: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Tree built in extended component
      var groups = this.get('list');
      var list = (0, _emberSelectUtilsTree.getDescendents)(groups);

      this.setProperties({ list: list, groups: groups });
    },

    options: computed('token', 'model.[]', 'values.[]', function () {
      if (this.get('shouldFilter')) {
        this.filterModel();
      }

      return this.get('groups');
    }),

    setVisibility: function setVisibility(list, token) {
      list.filter(function (el) {
        return isPresent(get(el, 'parentId'));
      }).filter(function (el) {
        return get(el, 'name').toLowerCase().indexOf(token) > -1;
      }).forEach(function (el) {
        el.set('isVisible', true);

        // Mark parent visible
        list.filter(function (x) {
          return x.id === get(el, 'parentId');
        }).shift().set('isVisible', true);
      });
    },

    upDownKeys: function upDownKeys(selected, event) {
      var list = this.get('list').filterBy('isVisible').filter(function (el) {
        return isPresent(get(el, 'parentId'));
      });

      this.move(list, selected, event.keyCode);
    }
  });
});
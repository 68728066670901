define('ember-tooltips/utils', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;
  var onComponentTarget = computed(function () {

    // the parentView is the lazy-render-wrapper
    // the grandparentView is the target component
    var grandparentView = this.get('parentView.parentView');

    if (!grandparentView) {
      console.warn('No grandparentView found');

      return null;
    } else if (!grandparentView.get('elementId')) {
      console.warn('No grandparentView.elementId');

      return null;
    } else {
      return '#' + grandparentView.get('elementId');
    }
  });
  exports.onComponentTarget = onComponentTarget;
});
define('ember-select/components/select-dropdown-option', ['exports', 'ember', 'ember-select/templates/components/select-dropdown-option'], function (exports, _ember, _emberSelectTemplatesComponentsSelectDropdownOption) {
  'use strict';

  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    layout: _emberSelectTemplatesComponentsSelectDropdownOption['default'],
    classNames: ['es-option'],
    classNameBindings: ['model.isSelected:es-highlight'],

    click: function click() {
      this.attrs.select(this.get('model'));
    },

    mouseEnter: function mouseEnter() {
      this.attrs.hover(this.get('model'));
    }
  });
});
define('ember-cli-notifications/components/notification-container', ['exports', 'ember', 'ember-cli-notifications/templates/components/notification-container'], function (exports, _ember, _emberCliNotificationsTemplatesComponentsNotificationContainer) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliNotificationsTemplatesComponentsNotificationContainer['default'],

    classNames: ['c-notification__container'],
    classNameBindings: ['computedPosition'],

    computedPosition: _ember['default'].computed('position', function () {
      if (this.get('position')) return 'c-notification__container--' + this.get('position');

      return 'c-notification__container--top';
    })
  });
});
define("ember-select/utils/view", ["exports"], function (exports) {
  "use strict";

  exports.bringInView = bringInView;

  function bringInView(parent, child) {
    var menu = document.querySelector(parent);
    var item = document.querySelector(child);

    // Ensure the elements still exist
    if (!menu || !item) {
      return;
    }

    var menuRect = menu.getBoundingClientRect();
    var itemRect = item.getBoundingClientRect();

    if (itemRect.bottom > menuRect.bottom || itemRect.top < menuRect.top) {
      menu.scrollTop = item.offsetTop + item.clientHeight - menu.offsetHeight;
    }
  }
});
define("ember-cli-pagination/remote/controller-mixin", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.Mixin.create({
    queryParams: ["page", "perPage"],

    page: Ember.computed.alias("content.page"),

    totalPages: Ember.computed.alias("content.totalPages"),

    pagedContent: Ember.computed.alias("content")
  });
});
define("ember-collapsible-panel/components/cp-panel/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "modules/ember-collapsible-panel/components/cp-panel/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["toggle", ["subexpr", "component", ["cp-panel-toggle"], ["on-click", ["subexpr", "action", ["toggleIsOpen"], [], ["loc", [null, [2, 47], [2, 70]]]], "isOpen", ["subexpr", "@mut", [["get", "isOpen", ["loc", [null, [2, 78], [2, 84]]]]], [], []]], ["loc", [null, [2, 9], [2, 85]]]], "body", ["subexpr", "component", ["cp-panel-body"], ["shouldAnimate", ["subexpr", "@mut", [["get", "shouldAnimate", ["loc", [null, [3, 48], [3, 61]]]]], [], []], "isOpen", ["subexpr", "@mut", [["get", "isOpen", ["loc", [null, [3, 69], [3, 75]]]]], [], []]], ["loc", [null, [3, 7], [3, 76]]]], "name", ["get", "name", ["loc", [null, [4, 7], [4, 11]]]], "isOpen", ["get", "isOpen", ["loc", [null, [5, 9], [5, 15]]]]], ["loc", [null, [1, 8], [6, 1]]]]], [], ["loc", [null, [1, 0], [6, 3]]]]],
      locals: [],
      templates: []
    };
  })());
});
define('ember-checkbox-with-label/components/checkbox-with-label', ['exports', 'ember', 'ember-checkbox-with-label/templates/components/checkbox-with-label'], function (exports, _ember, _emberCheckboxWithLabelTemplatesComponentsCheckboxWithLabel) {
  'use strict';

  var Component = _ember['default'].Component;
  var get = _ember['default'].get;

  var MyComponent = Component.extend({
    layout: _emberCheckboxWithLabelTemplatesComponentsCheckboxWithLabel['default'],

    tagName: 'label',

    classNames: ['checkbox-with-label'],

    actions: {
      toggleChecked: function toggleChecked() {
        var checked = get(this, 'checked');

        this.sendAction('update', !checked);
      }
    }
  });

  MyComponent.reopenClass({
    positionalParams: ['checked', 'text']
  });

  exports['default'] = MyComponent;
});
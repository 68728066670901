define('ember-select/utils/tree', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.buildTree = buildTree;
  exports.buildFlatList = buildFlatList;
  exports.getDescendents = getDescendents;

  var A = _ember['default'].A;
  var get = _ember['default'].get;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var ObjectProxy = _ember['default'].ObjectProxy;

  /* Build a tree (nested objects) from a plain array
   * using `id` and `parentId` as references for the
   * relationships. The `name` property is expected
   * for rendering. Optionally, `valueKey` can be
   * passed for `id` and `labelKey` for `name`.
   * If the model is flat, it will return a list.
   */

  function buildTree(model, options) {
    var tree = {};
    var roots = A();

    if (isEmpty(model)) {
      return roots;
    }

    var element = model[0] || get(model, 'firstObject');
    if (typeof element !== 'object') {
      // Not a model of objects, hence it should be a flat list
      return buildFlatList(model);
    }

    // Add all nodes to tree
    model.forEach(function (node) {
      var child = {
        content: node,
        children: A(),
        isSelected: false,
        isVisible: true
      };

      // Alternative name for `id`
      if (options.valueKey) {
        child.id = get(node, options.valueKey);
      }

      // Alternative name for `name`
      if (options.labelKey) {
        child.name = get(node, options.labelKey);
      }

      // Decide if node is expanded
      if (isPresent(options.isExpanded)) {
        child.isExpanded = options.isExpanded;
      }

      // Proxy options to keep model intact
      tree[get(child, 'id')] = ObjectProxy.create(child);
    });

    // Connect all children to their parent
    model.forEach(function (node) {
      var child = tree[get(node, options.valueKey || 'id')];
      var parent = get(node, 'parentId');

      if (isEmpty(parent)) {
        roots.push(child);
      } else {
        tree[parent].children.push(child);
      }
    });

    return roots;
  }

  // Builds a list of proxies from a model of values

  function buildFlatList(model) {
    var list = model.map(function (node) {
      return ObjectProxy.create({
        content: node,
        id: node,
        name: node,
        isSelected: false,
        isVisible: true
      });
    });

    return A(list);
  }

  function getDescendents(tree) {
    var descendents = A();

    tree.forEach(function (node) {
      descendents.pushObject(node);
      descendents.pushObjects(getDescendents(node.children));
    });

    return descendents;
  }
});
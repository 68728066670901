define('ember-cli-pagination/components/page-numbers', ['exports', 'ember-cli-pagination/util', 'ember-cli-pagination/lib/page-items', 'ember-cli-pagination/validate', 'ember-cli-pagination/templates/components/page-numbers'], function (exports, _emberCliPaginationUtil, _emberCliPaginationLibPageItems, _emberCliPaginationValidate, _emberCliPaginationTemplatesComponentsPageNumbers) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberCliPaginationTemplatesComponentsPageNumbers['default'],
    currentPage: Ember.computed.alias("content.page"),
    totalPages: Ember.computed.alias("content.totalPages"),

    hasPages: Ember.computed.gt('totalPages', 1),

    watchInvalidPage: Ember.observer("content", function () {
      var _this = this;

      var c = this.get('content');
      if (c && c.on) {
        c.on('invalidPage', function (e) {
          _this.sendAction('invalidPageAction', e);
        });
      }
    }),

    truncatePages: true,
    numPagesToShow: 10,

    validate: function validate() {
      if (_emberCliPaginationUtil['default'].isBlank(this.get('currentPage'))) {
        _emberCliPaginationValidate['default'].internalError("no currentPage for page-numbers");
      }
      if (_emberCliPaginationUtil['default'].isBlank(this.get('totalPages'))) {
        _emberCliPaginationValidate['default'].internalError('no totalPages for page-numbers');
      }
    },

    pageItemsObj: Ember.computed(function () {
      var result = _emberCliPaginationLibPageItems['default'].create({
        parent: this
      });

      Ember.defineProperty(result, 'currentPage', Ember.computed.alias("parent.currentPage"));
      Ember.defineProperty(result, 'totalPages', Ember.computed.alias("parent.totalPages"));
      Ember.defineProperty(result, 'truncatePages', Ember.computed.alias("parent.truncatePages"));
      Ember.defineProperty(result, 'numPagesToShow', Ember.computed.alias("parent.numPagesToShow"));
      Ember.defineProperty(result, 'showFL', Ember.computed.alias("parent.showFL"));

      return result;
    }),

    pageItems: Ember.computed("pageItemsObj.pageItems", "pageItemsObj", function () {
      this.validate();
      return this.get("pageItemsObj.pageItems");
    }),

    canStepForward: Ember.computed("currentPage", "totalPages", function () {
      var page = Number(this.get("currentPage"));
      var totalPages = Number(this.get("totalPages"));
      return page < totalPages;
    }),

    canStepBackward: Ember.computed("currentPage", function () {
      var page = Number(this.get("currentPage"));
      return page > 1;
    }),

    actions: {
      pageClicked: function pageClicked(number) {
        _emberCliPaginationUtil['default'].log("PageNumbers#pageClicked number " + number);
        this.set("currentPage", number);
        this.sendAction('action', number);
      },
      incrementPage: function incrementPage(num) {
        var currentPage = Number(this.get("currentPage")),
            totalPages = Number(this.get("totalPages"));

        if (currentPage === totalPages && num === 1) {
          return false;
        }
        if (currentPage <= 1 && num === -1) {
          return false;
        }
        this.incrementProperty('currentPage', num);

        var newPage = this.get('currentPage');
        this.sendAction('action', newPage);
      }
    }
  });
});
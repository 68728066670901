define('ember-debug-logger/initializers/debug-logger', ['exports', 'ember-debug-logger/utils/debug-logger'], function (exports, _emberDebugLoggerUtilsDebugLogger) {
  'use strict';

  exports.initialize = initialize;

  function initialize(container, app) {
    app.register('debug-logger:main', (0, _emberDebugLoggerUtilsDebugLogger['default'])(), { instantiate: false });

    ['route', 'component', 'controller', 'service'].forEach(function (type) {
      app.inject(type, 'debug', 'debug-logger:main');
    });
  }

  exports['default'] = {
    name: 'debug-logger',
    initialize: initialize
  };
});
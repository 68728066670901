define('ember-collapsible-panel/components/cp-panel/component', ['exports', 'ember', 'ember-collapsible-panel/components/cp-panel/template'], function (exports, _ember, _emberCollapsiblePanelComponentsCpPanelTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCollapsiblePanelComponentsCpPanelTemplate['default'],

    panelActions: _ember['default'].inject.service(),
    dependencyChecker: _ember['default'].inject.service(),
    shouldAnimate: _ember['default'].computed.and('dependencyChecker.hasLiquidFire', 'animate'),

    group: null, // passed in if rendered as part of a {{cp-panels}} group

    classNames: ['cp-Panel'],
    classNameBindings: ['isOpen:cp-is-open:cp-is-closed'],

    // Caller can overwrite
    name: _ember['default'].computed.oneWay('elementId'),

    panelState: _ember['default'].computed('name', function () {
      var name = this.get('name');
      // debugger;
      return this.get('panelActions.state.' + name);
    }),

    isOpen: _ember['default'].computed.readOnly('panelState.isOpen'),
    isClosed: _ember['default'].computed.not('isOpen'),

    panelsWrapper: null,
    animate: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // If caller passes in open=, use it
      if (this.get('open') !== undefined) {
        this.set('panelState.boundOpenState', this.get('open'));
      }
    },

    // Register with parent panels component
    maybeRegisterWithStateService: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', function () {
        var group = _this.get('group');

        if (group) {
          _this.get('panelState').set('group', group);
        }
      });
    }),

    actions: {
      toggleIsOpen: function toggleIsOpen() {
        this.get('panelActions').toggle(this.get('name'));
      }
    }
  });
});
define("ember-checkbox-with-label/templates/components/checkbox-with-label", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "modules/ember-checkbox-with-label/templates/components/checkbox-with-label.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "checkbox");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'checked');
        morphs[1] = dom.createAttrMorph(element0, 'onchange');
        morphs[2] = dom.createAttrMorph(element0, 'disabled');
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "checked", ["get", "checked", ["loc", [null, [2, 12], [2, 19]]]]], ["attribute", "onchange", ["subexpr", "action", ["toggleChecked"], ["value", "target.value"], ["loc", [null, [3, 11], [3, 58]]]]], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [4, 13], [4, 21]]]]], ["content", "text", ["loc", [null, [6, 0], [6, 8]]]]],
      locals: [],
      templates: []
    };
  })());
});
define('ember-collapsible-panel/components/cp-panels/component', ['exports', 'ember', 'ember-collapsible-panel/components/cp-panels/template'], function (exports, _ember, _emberCollapsiblePanelComponentsCpPanelsTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCollapsiblePanelComponentsCpPanelsTemplate['default'],

    classNames: 'cp-Panels',
    accordion: false,
    animate: true,

    _cpPanels: true,

    name: _ember['default'].computed.oneWay('elementId')
  });
});
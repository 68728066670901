define("ember-cli-pagination/validate", ["exports"], function (exports) {
  "use strict";

  var Validate = Ember.Object.extend();

  Validate.reopenClass({
    internalErrors: [],

    internalError: function internalError(str, obj) {
      this.internalErrors.push(str);
      console.warn(str);
      if (obj) {
        console.warn(obj);
      }
    },

    getLastInternalError: function getLastInternalError() {
      return this.internalErrors[this.internalErrors.length - 1];
    }
  });

  exports["default"] = Validate;
});
define('ember-collapsible-panel/components/cp-panel-body/component', ['exports', 'ember', 'ember-collapsible-panel/components/cp-panel-body/template'], function (exports, _ember, _emberCollapsiblePanelComponentsCpPanelBodyTemplate) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCollapsiblePanelComponentsCpPanelBodyTemplate['default'],

    classNames: ['cp-Panel-body'],
    classNameBindings: ['isOpen:cp-is-open']

  });
});
define('ember-remodal/components/er-button', ['exports', 'ember', 'ember-remodal/templates/components/er-button'], function (exports, _ember, _emberRemodalTemplatesComponentsErButton) {
  'use strict';

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    layout: _emberRemodalTemplatesComponentsErButton['default'],
    tagName: 'span',

    click: function click() {
      this.sendAction();
    },

    destination: computed('modalId', {
      get: function get() {
        var modalId = this.get('modalId');

        if (modalId) {
          return 'open-button-' + modalId;
        }
      }
    })
  });
});
define('ember-tooltips/components/popover-on-element', ['exports', 'ember-tooltips/components/lazy-render-wrapper'], function (exports, _emberTooltipsComponentsLazyRenderWrapper) {
	'use strict';

	exports['default'] = _emberTooltipsComponentsLazyRenderWrapper['default'].extend({

		tetherComponentName: 'tether-popover-on-element',

		childView: null, // this is set during the childView's didRender and is needed for the hide action
		actions: {
			hide: function hide() {
				var childView = this.get('childView');
				childView.send('hide');
			}
		}
	});
});